'use client';

import { useTranslations } from 'next-intl';
import { Card } from '~/components/ui/card';
import {
  Briefcase,
  Camera,
  Mic,
  Lightbulb,
  Monitor,
  Settings,
  Focus,
  Route,
  Car,
  Truck,
  Bike,
  Tractor,
  Shapes
} from 'lucide-react';
import Excavator from '../icons/excavator';
import { ReactElement } from 'react';
import { Link } from '~/navigation';
import { RouterOutputs } from '~/trpc/shared';

const CategoryCard = ({ title, icon }: { title: string; icon: ReactElement }) => {
  return (
    <Card className="flex items-center shadow-3xl rounded-lg overflow-hidden hover:bg-gray-100 transition-all cursor-pointer p-6">
      {icon}
      <h3 className="pl-3 text-lg text-gray-800 font-medium leading-tight">{title}</h3>
    </Card>
  );
};

export default function Categories(props: { categories: RouterOutputs['product']['listCategories'] }) {
  const t = useTranslations();

  let visibleCategories = props.categories.map((category) => {
    if (category.visibility) {
      return category.name;
    }
  });

  return (
    <div className="flex gap-4 grid-cols-1 mb-12 w-full">
      {visibleCategories.includes('Cars') && (
        <Link href={'/search/?categories=6bcd0655-917e-4639-ae30-45ad720d7e28'} className="w-full">
          <CategoryCard title={t('Cars')} icon={<Car className="text-primary" size={60} strokeWidth={0.9} />} />
        </Link>
      )}
      {visibleCategories.includes('Heavy machinery') && (
        <Link href={'/search/?categories=9b89c58e-1c25-4b54-a76b-79a31618da5e'} className="w-full">
          <CategoryCard
            title={t('Heavy machinery')}
            icon={<Truck className="text-primary" size={60} strokeWidth={0.9} />}
          />
        </Link>
      )}
      {visibleCategories.includes('Mototechnics') && (
        <Link href={'/search/?categories=0564aecb-822c-4bed-9cfb-a66e06b70a42'} className="w-full">
          <CategoryCard
            title={t('Mototechnics')}
            icon={<Bike className="text-primary" size={60} strokeWidth={0.9} />}
          />
        </Link>
      )}
      {visibleCategories.includes('Agro') && (
        <Link href={'/search/?categories=605b9f2c-dec5-4777-ab20-8b198ebb2c4c'} className="w-full">
          <CategoryCard title={t('Agro')} icon={<Tractor className="text-primary" size={60} strokeWidth={0.9} />} />
        </Link>
      )}
      {visibleCategories.includes('Construction') && (
        <Link href={'/search/?categories=669fc121-75c9-49a1-b5e7-62a7f00b0b95'} className="w-full">
          <CategoryCard
            title={t('Construction')}
            icon={<Excavator thin className="text-primary w-[60px] h-[60px] fill-primary" />}
          />
        </Link>
      )}
    </div>
  );
}
