export default function Excavator({ className = '', thin = false }: { className?: string; thin?: boolean }) {
  if (thin)
    return (
      <svg
      className={className}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 800 800"
      >
        <g>
          <g>
            <path
              d="M116.4,673c-40.8,0-73.9-33.2-73.9-73.9s33.2-73.9,73.9-73.9h88.1v-54.7H35.4C16.9,470.5,2,455.5,2,437v-81
               c0-18.4,15-33.4,33.4-33.4h169.1V193.9c0-18.4,15-33.4,33.4-33.4h121.6c12.7,0,24.2,7.1,29.9,18.5l40.5,81c0.5,1.1,1,2.1,1.4,3.2
               l3.9,10.3L579,129.7c6.3-6.3,14.7-9.8,23.6-9.8c2.7,0,5.4,0.3,8.1,1l162.1,40.5c16.3,4.1,27,19.6,25,36.3l-71.4,265.6
               c-4.4,16.3-13.7,28.4-27.1,35.1c-8.4,4.2-18.3,6.3-29.5,6.3c-17.3,0-38.6-5.1-63.2-15c-15.8-6.4-27.2-17.6-33.1-32.4
               C563,431,586,350.4,595.7,321.6l0.1-0.2l0.1-0.2c1.6-5.8,6.7-9.6,12.7-9.6c1.2,0,2.4,0.2,3.6,0.5c3.3,0.9,6.1,3.1,7.8,6.2
               c1.7,3.1,2.2,6.6,1.2,10l-12.4,44.6l107.9,24.9l45.6-170.4l-133-16.9L425.9,458.2c-6.4,7.8-15.8,12.2-25.8,12.2h-47.6v54.7h169.2
               c40.8,0,73.9,33.2,73.9,73.9S562.4,673,521.6,673H116.4z M116.4,551.5c-26.2,0-47.6,21.4-47.6,47.6c0,26.2,21.4,47.6,47.6,47.6
               h405.2c26.2,0,47.6-21.4,47.6-47.6c0-26.2-21.4-47.6-47.6-47.6H116.4z M230.9,525.2h95.2v-54.7h-95.2V525.2z M598.7,404.6
               c-3.3,12.2-6.1,29.7-0.7,43.2c3.3,8.2,9.3,13.9,18.4,17.6c21.5,8.7,39.4,13.1,53.2,13.1c7.2,0,13.2-1.2,17.9-3.5
               c4.3-2.1,10.3-6.4,13.4-18.3l9-33.4L600.5,398L598.7,404.6z M28.3,444.1h378.8l0-95.2H28.3V444.1z M433.5,312.6v95l184.8-225
               l150.5,19.1l4.5-12.7l-7.6-2l-165.3-41.3L433.5,312.6z M230.9,322.6h176.3l0-49.3l-43.2-86.5h-133V322.6z"
            />
          </g>
        </g>
      </svg>
    );

  return (
    <svg className={className} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.985 511.985">
      <g>
        <g>
          <path
            d="M492.629,103.143l-102.409-25.6c-2.057-0.512-4.139-0.768-6.212-0.768c-6.707,0-13.244,2.637-18.099,7.501l-85.931,85.931
			c-0.316-0.845-0.674-1.664-1.075-2.475l-25.6-51.2c-4.335-8.67-13.201-14.157-22.895-14.157H153.6c-14.14,0-25.6,11.46-25.6,25.6
			v76.834H25.6c-14.14,0-25.6,11.46-25.6,25.6v51.2c0,14.14,11.46,25.6,25.6,25.6h102.409v25.6h-51.2
			c-28.237,0-51.2,22.972-51.2,51.2c0,28.237,22.972,51.2,51.2,51.2h256.017c28.237,0,51.2-22.972,51.2-51.2
			c0-28.237-22.972-51.2-51.2-51.2H230.417v-25.6h25.6c7.663,0,14.925-3.43,19.78-9.353l127.036-154.684l76.476,9.719
			l-26.377,98.517l-59.409-13.687l6.554-23.654c1.903-6.81-2.099-13.867-8.9-15.753c-6.955-1.826-13.875,2.116-15.753,8.926
			c0,0-22.639,67.038-13.978,88.832c4.173,10.547,12.279,18.5,23.373,22.989c16.205,6.554,30.003,9.822,41.626,9.822
			c7.825,0,14.703-1.485,20.651-4.454c9.626-4.813,16.375-13.466,19.473-25.011l45.201-168.132
			C513.434,118.486,505.301,106.309,492.629,103.143z M332.826,358.418c14.14,0,25.6,11.46,25.6,25.6s-11.46,25.6-25.6,25.6H76.809
			c-14.14,0-25.6-11.46-25.6-25.6s11.46-25.6,25.6-25.6H332.826z M153.609,332.818v-25.6h51.2v25.6H153.609z M256.017,281.61H25.6
			v-51.2h128.009h102.409V281.61z M256.017,204.801H153.609v-76.809h76.809l25.6,51.208V204.801z M441.839,292.814
			c-1.527,5.777-4.104,7.697-6.153,8.73c-4.301,2.15-15.454,4.224-41.25-6.187c-4.651-1.886-7.552-4.642-9.173-8.661
			c-2.85-7.117-1.425-16.862,0.623-24.44l60.425,13.918L441.839,292.814z M485.99,127.95l-93.961-11.947L281.626,250.437V204.81
			l102.409-102.409l101.973,25.498L485.99,127.95z"
          />
        </g>
      </g>
    </svg>
  );
}
