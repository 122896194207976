'use client';

import { RouterOutputs } from '~/trpc/shared';
import { useTranslations } from 'next-intl';
import Countdown from './countdown';

import Image from 'next/image';
import { Link } from '~/navigation';

import { Card } from '~/components/ui/card';
import { Typography } from '~/components/ui/typography';
import { Badge } from '~/components/ui/badge';

import { fCurrency, fNumber } from '~/utils/formatNumber';
import subDays from 'date-fns/subDays';
import { Clock, Gavel } from 'lucide-react';
import { api } from '~/trpc/react';

export default function Product(props: {
  product: RouterOutputs['product']['search'][number] | RouterOutputs['product']['recent'][number];
  userId?: string;
}) {
  const t = useTranslations();
  const { data: highestBid, isLoading: highestBidLoading } = api.product.highestBid.useQuery({
    productId: props.product.product.id
  });

  let properties = [
    ...(props.product.product.year ? [props.product.product.year] : []),
    ...(props.product.product.mileage ? [fNumber(props.product.product.mileage) + ' km'] : []),
    ...(props.product.product.fuel ? [t(props.product.product.fuel)] : [])
  ];
  const propertiesString = properties.join(' / ');

  const startsInTheFuture = props.product.product.auctionStartDate > new Date();
  const isEnded = props.product.product.auctionEndDate < new Date();

  return (
    <Link href={`/listing/${props.product.product.handle}`}>
      <Card
        key={props.product.product.id}
        className="shadow-3xl overflow-hidden hover:shadow-2xl relative hover:z-30 transition-all group"
      >
        <div className="w-full aspect-video relative overflow-hidden">
          <Image
            alt={props.product.product.name}
            src={props.product.product.thumbnail}
            fill
            className="object-cover"
            sizes="(max-width: 768px) 100vw, 33vw"
          />
          {(props.product.product?.createdAt || new Date()) > subDays(new Date(), 3) && (
            <div className="absolute top-3 left-4 z-10">
              <Badge className="text-xs">{t('new')}</Badge>
            </div>
          )}
        </div>

        <div className="p-4">
          <Typography variant="h3" className="text-base font-medium line-clamp-1">
            {props.product.product.name}
          </Typography>
          <Typography variant="h3" className="text-sm font-light opacity-70">
            {propertiesString}
          </Typography>
          <hr className="mt-3 mb-4" />

          <div className="flex flex-row items-center mb-1">
            <Clock size={16} className="opacity-50 mr-2" />
            <Typography variant="h3" className="text-sm font-light">
              {startsInTheFuture ? (
                <>
                  {t('startingIn')} <Countdown date={props.product.product.auctionStartDate} />
                </>
              ) : isEnded ? (
                <Countdown date={props.product.product.auctionEndDate} />
              ) : (
                <>
                  {t('endsIn')} <Countdown date={props.product.product.auctionEndDate} />
                </>
              )}
            </Typography>
          </div>
          <div className="flex flex-row items-center">
            <Gavel size={16} className="opacity-50 mr-2" />
            <Typography variant="h3" className="text-sm font-light">
              {fCurrency(
                highestBidLoading || !highestBid || !highestBid[0]
                  ? props.product.product.startingPrice
                  : parseFloat(highestBid[0]?.data || '0') === 0
                  ? props.product.product.startingPrice
                  : parseFloat(highestBid[0]?.data || '0')
              )}
              €
            </Typography>
          </div>
        </div>
      </Card>
    </Link>
  );
}
