'use client';

import { useTranslations } from 'next-intl';
import { Card } from '~/components/ui/card';
import {
  Briefcase,
  Camera,
  Mic,
  Lightbulb,
  Monitor,
  Settings,
  Focus,
  Route,
  Car,
  Truck,
  Bike,
  Tractor,
  Shapes,
  HardHat
} from 'lucide-react';
import Excavator from '../icons/excavator';
import { ReactElement, useRef, useState } from 'react';
import { Link } from '~/navigation';
import { cn } from '~/lib/utils';

const CategoryCard = ({ title, icon }: { title: string; icon: ReactElement }) => {
  return (
    <div className="text-center w-[90px] my-6">
      <Card className="mx-auto aspect-square w-[60px] h-[60px] rounded-full flex justify-center items-center shadow-3xl overflow-hidden hover:bg-gray-100 transition-all cursor-pointer p-1">
        {icon}
      </Card>
      <h3 className="text-sm mx-auto text-gray-800 text-center leading-tight pt-2">{title}</h3>
    </div>
  );
};

export default function MobileCategories() {
  const t = useTranslations();
  const [rowPosition, setRowPosition] = useState<'start' | 'middle' | 'end'>('start');
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
      if (scrollLeft === 0 && rowPosition !== 'start') {
        setRowPosition('start');
      } else if (scrollLeft + clientWidth === scrollWidth && rowPosition !== 'end') {
        setRowPosition('end');
      } else if (rowPosition !== 'middle') {
        setRowPosition('middle');
      }
    }
  };

  return (
    <div
      className={cn(
        "w-full relative after:content-['']",
        'after:absolute after:bottom-0 after:right-0 after:w-[170px] after:z-10 after:bg-gradient-to-r after:from-transparent after:to-white after:h-full after:pointer-events-none after:transition-all',
        'before:absolute before:bottom-0 before:left-0-0 before:w-[170px] before:z-10 before:bg-gradient-to-l before:from-transparent before:to-white before:h-full before:pointer-events-none before:transition-all',
        (rowPosition === 'start' ? 'before:opacity-0' : 'before:opacity-100'),
        (rowPosition === 'end' ? 'after:opacity-0' : 'after:opacity-100')
      )}
    >
      <div
        ref={scrollRef}
        onScroll={handleScroll}
        className="flex flex-row overflow-x-auto gap-1 grid-cols-1 mb-2 px-2 relativ w-full"
      >
        <Link href={'/search/?categories=6bcd0655-917e-4639-ae30-45ad720d7e28'}>
          <CategoryCard title={t('Cars')} icon={<Car className="text-primary" size={30} strokeWidth={1} />} />
        </Link>
        <Link href={'/search/?categories=9b89c58e-1c25-4b54-a76b-79a31618da5e'}>
          <CategoryCard title={t('machinery')} icon={<Truck className="text-primary" size={30} strokeWidth={1} />} />
        </Link>
        <Link href={'/search/?categories=0564aecb-822c-4bed-9cfb-a66e06b70a42'}>
          <CategoryCard title={t('Mototechnics')} icon={<Bike className="text-primary" size={30} strokeWidth={1} />} />
        </Link>
        <Link href={'/search/?categories=605b9f2c-dec5-4777-ab20-8b198ebb2c4c'}>
          <CategoryCard title={t('Agro')} icon={<Tractor className="text-primary" size={30} strokeWidth={1} />} />
        </Link>
        <Link href={'/search/?categories=669fc121-75c9-49a1-b5e7-62a7f00b0b95'}>
          <CategoryCard title={t('Construction')} icon={<Excavator className="text-primary fill-primary w-[30px] h-[30px]" />} />
        </Link>
        <Link href={'/search'}>
          <CategoryCard title={t('allListings')} icon={<Shapes className="text-primary" size={30} strokeWidth={1} />} />
        </Link>
      </div>
    </div>
  );
}
